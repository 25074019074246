import logo_cm from '../assets/logo_cm.svg'
import cmendez_portrait from '../assets/cmendez_portrait.jpg'
import circle from '../assets/circle.svg'
import shape from '../assets/shape.svg'
import grain from '../assets/grain.png'
import logo_book512 from '../assets/logo_book512.png'

const images = {
    logo_cm,
    cmendez_portrait,
    circle,
    shape,
    grain,
    logo_book512
};
export default images;